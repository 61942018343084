<template>
  <div>
    <div class="container-fluid">
      <div class="p-5 text-center" v-if="loading">
        <b-spinner /><br />
        aguarde...
      </div>
      <div class="text-right">
        <b-button variant="primary" @click="novo">
          <i class="fa fa-plus" /> Solicitar Exame
        </b-button>
      </div>

      <EmptyState v-if="exames.length == 0">
        <h3>
          Nenhum exame encontrado!
        </h3>
        <p>
          Clique em
          <b-button class="mx-1" variant="primary" @click="novo">
            <i class="fa fa-plus" /> Solicitar Exame
          </b-button>
          para solicitar.
        </p>
      </EmptyState>

      <div v-if="!loading" class="listaItens">
  
        <paciente-item-card
          v-for="(exame, index) in exames"
          @click.native="editar(exame)"
          :class="{ used: exame.dt_utilizacao }"
          :key="`exame_${exame.id}_i_${index}`"
          :objeto="exame"
          :tipo="`Exame`"
        />
      </div>
      <b-modal id="modal-add-exame" hide-footer size="md" title="Solicitar Exame">
        <!-- <template #modal-footer="{cancel}">
          <b-btn variant="primary" @click="salvar">
            <i class="fas fa-save"></i> Salvar</b-btn
          >
          <b-btn variant="secondary" @click="cancel">
            <i class="fas fa-ban"></i> Cancelar</b-btn
          >
        </template> -->
        <paciente-exame-form
          ref="PacienteExameForm"
          @salvou="onSalvou($event)"
          :solicitacao_edit="solicitacao_edit"
          @close="close"
          :horario="horario"
          :paciente="paciente"
          showButtons
        ></paciente-exame-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import PacienteItemCard from "../PacienteItemCard.vue";
import PacienteExameForm from "./PacienteExameForm.vue";
import SolicitacaoExamesLib from "../../../../libs/SolicitacaoExamesLib";
import moment from "moment";
import EmptyState from "../../../common/EmptyState.vue";
export default {
  name:"PacienteExames",
  components: { PacienteItemCard, PacienteExameForm, EmptyState },
  props: {
    paciente: Object,
    horario: Object,
  },

  mounted() {
    this.carregaExames();
  },
  data() {
    return {
      loading: false,
      loadingSecundario: false,
      exames: [],
      solicitacao_edit: {},
  
    };
  },
  watch: {},
  computed: {},
  methods: {
    novo() {
      this.solicitacao_edit = {};
      this.$bvModal.show("modal-add-exame");
    },
    salvar() {
      this.$refs.PacienteExameForm.salvar();
    },
    async onSalvou(evt) {
      if (typeof evt?.close != "boolean") evt.close = true;
      console.log("evento: ", evt);

      if (evt.close) {
        this.$bvModal.hide("modal-add-exame");
      }
      await this.carregaExames(evt?.id, evt?.cb);
    },
    close() {
      this.$bvModal.hide("modal-add-exame");
    },
    editar(exame) {
      this.solicitacao_edit = exame;
      this.$bvModal.show("modal-add-exame");
    },
    async carregaExames(id = "", cb = null) {
      this.$forceUpdate();

      try {
        this.exames = (
          await SolicitacaoExamesLib.get({
            paciente_id: this.paciente.id,
          })
        ).map((exame) => {
          return { ...exame, data: moment(exame.data).format("YYYY-MM-DD") };
        });

        if ((this.solicitacao_edit && this.solicitacao_edit?.id) || id) {
          const mergeObject = this.exames.find(
            (exame) => exame.id == this.solicitacao_edit.id || exame.id === id
          );
          console.log("mergeObject", mergeObject);

          if (mergeObject) {
            this.solicitacao_edit = Object.assign(
              this.solicitacao_edit,
              mergeObject
            );
           // console.log("solicitacao_edit changed", this.solicitacao_edit);
            if (typeof cb === "function") {
              cb(this.solicitacao_edit);
            }
          }
        }
        await this.$nextTick();
      } catch (error) {
        console.log(error);
        // this.showToast("error", "Erro ao carregar pacientes!");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/paciente.scss";
</style>
